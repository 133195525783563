import React, { useState } from 'react';
import { Factory } from '../../controllers/ControllerFactory';
import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import './AttendeeSearch.scss';
import QRScan from './QRScan';

interface AttendeeSearchProps {
    setGalaAttendees: (tGalaAttendees: IGalaAttendee[]) => void;
    setCheckedInArr?: (ids: string[]) => void;
}

const AttendeeSearch: React.FC<AttendeeSearchProps> = ({
    setGalaAttendees, setCheckedInArr
}) => {

    const [name, setName] = useState('');
    const [ticketNum, setTicketNum] = useState<any>('');
    const [paddleNum, setPhoneNum] = useState<any>('');
    const [attendeeId, setAttendeeId] = useState('');

    const [showScanner, setShowScanner] = useState(false);

    const galaAttendeeController = Factory.GalaAttendeeController;

    const handleSearch = async (t?: string) => {
        try {
            const galaAttendeesResponse = await galaAttendeeController.getByParam(
                ticketNum,
                name,
                t ? t : attendeeId,
                paddleNum,
            )
            setGalaAttendees(galaAttendeesResponse.data.galaAttendees);
            if (setCheckedInArr) {
                setCheckedInArr(galaAttendeesResponse.data.checkedIn);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const reset = () => {
        setName('')
        setTicketNum('')
        setPhoneNum('')
        setAttendeeId('')
    }

    const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        reset();
        setName(val);
    }

    const changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        reset();
        setAttendeeId(val);
    }

    const changeTicketNum = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        reset();
        setTicketNum(parseInt(val, 10));
    }

    const changePhoneNum = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        reset();
        setPhoneNum(parseInt(val, 10));
    }

    const handleScan = async (val: string) => {
        setShowScanner(false)
        // https://www.sdcfund.org/redeem/6497a7e6d2e9cb00147a9b9c
        const id = val.split('/').pop();
        setAttendeeId(id as string);
        await handleSearch(id as string);
    }

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleSearch();
    };

    return (
        <div className="attendeeSearch">
            <header>Search for Attendee</header>

            <div className="inputWrapper2">
                <button className="scannerButton" onClick={() => setShowScanner(!showScanner)}>
                    {showScanner ? 'Back to manual entry' : 'Scan QR Code'}
                </button>
            </div>

            {!showScanner && (
                <form onSubmit={onSubmit}>
                    <div className="inputWrapper">
                        <input value={name} onChange={changeName} placeholder="Name" />
                        <input value={attendeeId} onChange={changeCode} placeholder="Attendee Id" />
                        <input type="number" value={ticketNum} inputMode="numeric" onChange={changeTicketNum} placeholder="Ticket Number" />
                        <input type="number" value={paddleNum} inputMode="numeric" onChange={changePhoneNum} placeholder="Phone Number" />
                    </div>

                    <button className="search" type="submit">search</button>
                </form>
            )}


            {showScanner && <QRScan setValue={handleScan} /> }

        </div>
    );
};

export default AttendeeSearch;
