import { IonContent, IonPage } from '@ionic/react';
import { useContext, useState } from 'react';
// @ts-ignore
import sdcLogo from '../../assets/logo.svg';
import mascotImg from '../../assets/mascot.png';
import { Factory } from '../../controllers/ControllerFactory';
import { ToastController } from '../../controllers/ToastController';
import { StaffContext, StaffContextType } from '../../services/StaffContext';
import './Login.scss';

const Login: React.FC = () => {
  // Context
  const { setStaff } = useContext(StaffContext) as StaffContextType;
  // Controllers
  const staffController = Factory.StaffController;

  // States
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');

  const [loginDisabled, setLoginDisabled] = useState(true);

  // State Functions
  const changePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setPhone(val);
  };

  const changeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setCode(val);
  };

  // Functions
  // const [HGCLoading, setHGCLoading] = useState(false);
  // const handleGetCode = async (e: any) => {
  //   e.preventDefault();
  //   if (HGCLoading) return;
  //   setHGCLoading(true);
  //   try {
  //     // await staffController.getCode(phone);
  //     if (phone === "09032022")
  //     setLoginDisabled(false);
  //   } catch (err: any) {
  //     console.log(err);
  //     alert(err);
  //     ToastController.error(err.response.data.message);
  //   }
  //   setHGCLoading(false);
  // };


  // const [OLSLoading, setOLSLoading] = useState(false);
  const onLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (OLSLoading) return;
    // setOLSLoading(true);
    try {
      if (phone !== "01272024" || code !== 'SDCBIGDAY') {
        throw new Error('Incorrect credentials');
      }
      await staffController.login(setStaff);
    } catch (err: any) {
      alert(err);
      // ToastController.error(err.response.data.message);
      // setOLSLoading(false);
    }
    // setOLSLoading(false);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="loginContent" scrollY={false}>
        <div className="container loginContainer">
          <img src={sdcLogo} alt="logo" className="sdcLogo" />
          <h2>Empowering Youth to Empower Children with Disabilities</h2>
          <div className="loginContent">
            <img src={mascotImg} alt="mascotImg" className="mascot" />
            <form className="getCode" onSubmit={onLoginSubmit}>
              <input type="text" className="phoneInput" inputMode="numeric" value={phone} onChange={changePhone} placeholder="Username" />
              {/* <button type="submit" className="">get code</button> */}
            {/* </form> */}
            {/* {!loginDisabled && ( */}
              {/* <form className="login" onSubmit={onLoginSubmit}> */}
                <input type="text" className="codeInput" value={code} onChange={changeCode} placeholder="Password" />
                <button type="submit" className="">login</button>
              </form>
            {/* )} */}
          </div>
        </div>
      </IonContent>
    </IonPage >
  );
};

export default Login;
