/* eslint-disable react-hooks/exhaustive-deps */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState, useRef } from 'react';
import Stripe from 'stripe';
import closeIcon from '../../assets/close.svg';
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { Factory } from '../../controllers/ControllerFactory';
import { StripePayActionType } from '../../models/galaGroup/galaStripePay/galaStripePayFields';
import { IGalaStripePay } from '../../models/galaGroup/galaStripePay/IGalaStripePay';
import { amountToString } from '../../utils/global';

import { IGalaAttendee } from '../../models/galaGroup/galaAttendee/iGalaAttendee';
import { IGalaDonationPool } from '../../models/galaGroup/galaLive/galaDonationPool/iGalaDonationPool';
import './Donations.scss';

import CanvasDraw from 'react-canvas-draw';
import { Config } from '../../Config';

enum ScreenType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  INIT = 'INIT',
  PAID = 'PAID'
}

const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY);

interface DonationProps {
  galaAttendee: IGalaAttendee;
  closeModal: () => void;
}
const Donations: React.FC<DonationProps> = ({
  galaAttendee, closeModal
}) => {
  const galaStripePayController = Factory.GalaStripePayController;
  const galaAttendeeController = Factory.GalaAttendeeController;
  const galaLiveController = Factory.GalaLiveController;
  const [screenType, setScreenType] = useState(ScreenType.ONLINE);

  const [galaStripePay, setGalaStripePay] = useState<IGalaStripePay | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>([]);
  const [paymentMethodId, setPaymentMethodId] = useState<string | undefined>();

  const [donationPools, setDonationPools] = useState<IGalaDonationPool[]>([]);
  const [donationPool, setDonationPool] = useState<IGalaDonationPool | null>(null);

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>, f: React.Dispatch<React.SetStateAction<string>>) => {
    const val = e.target.value;
    f(val);
  }

  const selectCard = (tPaymentMethodId: string) => {
    setClientSecret(null);
    if (paymentMethodId === tPaymentMethodId) {
      setPaymentMethodId(undefined);
      return;
    }
    setPaymentMethodId(tPaymentMethodId);
  }

  const [donationAmt, setDonationAmount] = useState<any>("");

  const changeDonationAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dollarCents = e.target.value.split('.');
    if (dollarCents.length === 2 && dollarCents[1].length > 2) {
      e.preventDefault();
    } else setDonationAmount(e.target.value);
  };

  const createStripePay = async () => {
    if (galaAttendee === null || donationPool === null) return;
    if (donationAmt === '' || donationAmt <= 0) {
      document.querySelector('div.donationsContainer')!.scrollIntoView()
      document.getElementById('donationAmt')!.classList.add('error');
    }
    const stripePayResp = await galaStripePayController.createStripePay(
      100 * donationAmt, galaAttendee._id, StripePayActionType.donation, {
      galaDonationPoolId: donationPool._id,
      fName: fName,
      lName: lName,
      address: address,
      postalCode: postalCode,
      city: city,
      province: province,
      country: country,
      email: email,
      phone: phone,
      notes: notes,
    },
      paymentMethodId
    )
    setGalaStripePay(stripePayResp.data.galaStripePay);
    setClientSecret(stripePayResp.data.clientSecret);
  }

  const handleContinue = async () => {
    if (galaStripePay === null) return;
    const resp = await galaStripePayController.confirmPayment(galaStripePay._id);
    
    setClientSecret(null);
    setPaymentMethodId(undefined);
    setScreenType(ScreenType.PAID);
  }

  const renderExpDate = (month: number, year: number) => {
    const sMonth = month.toString();
    const sYear = year.toString().substring(2);
    if (sMonth.length === 1) return `0${sMonth}/${sYear}`;
    return `${sMonth}/${sYear}`;
  }

  const renderPaymentMethods = () => (
    <div className="paymentMethods">
      {paymentMethods.map((paymentMethod: Stripe.PaymentMethod) => (
        <>
          {paymentMethod.card !== undefined && (
            <div
              className={`paymentMethod ${paymentMethodId === paymentMethod.id ? 'active' : ''}`}
              onClick={() => selectCard(paymentMethod.id)}
            >
              <h6>
                {paymentMethod.card.brand}
                <span>{` - ${paymentMethod.card.last4}`}</span>
              </h6>


              <p>{renderExpDate(paymentMethod.card.exp_month, paymentMethod.card.exp_year)}</p>
            </div>
          )}
        </>
      ))}
    </div>
  );

  const renderDonationPools = () => (
    <div className="donationPools">
      {donationPools.filter((dP) => dP.isOpen).map((dP) => (
        <button className="donationPool" onClick={() => setDonationPool(dP)}>{dP.name.en}</button>
      ))}
    </div>
  )

  const sigRef = useRef<any>();

  useEffect(() => {
    const getPaymentMethods = async () => {
      if (galaAttendee === null) return;
      const paymentMethodsResponse = await galaAttendeeController.getPaymentMethods(
        galaAttendee._id,
      );
      setPaymentMethods(paymentMethodsResponse.data.paymentMethods.data);
    }

    const getDonationPools = async () => {
      const galaLiveResponse = await galaLiveController.getGalaLive();
      console.log(galaLiveResponse);
      setDonationPools(galaLiveResponse.data.galaLive.galaDonationPools);
      setDonationPool(galaLiveResponse.data.galaLive.galaDonationPools[0])
    }
    getDonationPools();
    getPaymentMethods();
  }, [])

  useEffect(() => {
    if (donationAmt === 0) {
      setDonationAmount("");
    }
  }, [donationAmt])

  useEffect(() => {
    if (galaAttendee) {
      setFName(galaAttendee.fName)
      setLName(galaAttendee.lName)
      setAddress(galaAttendee.address)
      setPostalCode(galaAttendee.postalCode)
      setCity(galaAttendee.city)
      setProvince(galaAttendee.province)
      setCountry(galaAttendee.country)
      setEmail(galaAttendee.email)
      setPhone(galaAttendee.phone)
      setNotes('');
    }
  }, [screenType])

  useEffect(() => {
    setDonationAmount("");
  }, [galaAttendee]);

  const clearCanvas = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  }

  return (
    <>
      <div className="donationsContent">
        <div className="donationsContainer">
          <div className="content">
            <header>
              Donation
              <img src={closeIcon} alt="close" className="close" onClick={closeModal} />
            </header>
            <h4>{galaAttendee.ticketNum} - {galaAttendee.fName} {galaAttendee.lName}</h4>
            {screenType === ScreenType.INIT && (
              <div className="paymentSelector">
                <button onClick={() => setScreenType(ScreenType.ONLINE)}>in app donation</button>
                <button onClick={() => setScreenType(ScreenType.OFFLINE)}>offline donation</button>
              </div>
            )}
            {screenType === ScreenType.ONLINE && (
              <>
                {donationPool === null && (
                  <>

                    {renderDonationPools()}
                  </>
                )}
                {donationPool !== null && (
                  <div className="donationPool">
                    <h3>{donationPool.name.en}</h3>
                    <p>{donationPool.desc.en}</p>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <input placeholder="Donation Amount" id="donationAmt" type="number" inputMode="decimal" value={donationAmt} onChange={changeDonationAmount} />
                      <input placeholder="First Name" value={fName} onChange={(e) => changeValue(e, setFName)} />
                      <input placeholder="Last Name" value={lName} onChange={(e) => changeValue(e, setLName)} />
                      <input placeholder="Address" value={address} onChange={(e) => changeValue(e, setAddress)} />
                      <input placeholder="Postal Code" value={postalCode} onChange={(e) => changeValue(e, setPostalCode)} />
                      <input placeholder="City" value={city} onChange={(e) => changeValue(e, setCity)} />
                      <input placeholder="Province" value={province} onChange={(e) => changeValue(e, setProvince)} />
                      <input placeholder="Country" value={country} onChange={(e) => changeValue(e, setCountry)} />
                      <input placeholder="Email" value={email} onChange={(e) => changeValue(e, setEmail)} />
                      <input placeholder="Phone" value={phone} onChange={(e) => changeValue(e, setPhone)} />
                      <input placeholder="Notes" value={notes} onChange={(e) => changeValue(e, setNotes)} />
                      {renderPaymentMethods()}
                      <h4 style={{ margin: 0 }}>Please screenshot this signature and send to SDC</h4>
                      <h5>{galaAttendee.fName} {galaAttendee.lName} - {galaAttendee.ticketNum} - {new Date().getHours()}:{new Date().getMinutes()}</h5>
                      <CanvasDraw
                        ref={sigRef}
                        className="signatureCanvas"
                        brushRadius={2}
                        lazyRadius={2}
                      />
                      <button className="clear" onClick={clearCanvas}>Clear Signature</button>
                      {clientSecret === null && (
                        <button onClick={createStripePay}>
                          {paymentMethodId !== undefined ? 'Pay' : 'New Payment Method'}
                        </button>
                      )}
                      {clientSecret !== null && (
                        <div>
                          {/* <button onClick={}>Back</button> */}
                          <Elements
                            stripe={stripePromise}
                            options={{ clientSecret: clientSecret }}
                          >
                            <CheckoutForm
                              paymentMethodId={paymentMethodId}
                              clientSecret={clientSecret}
                              handleContinue={handleContinue}
                            />
                          </Elements>
                        </div>
                      )}
                    </form>
                  </div>
                )}


              </>
            )}
            {screenType === ScreenType.OFFLINE && (
              <div className="offline">
                Please find a volunteer
                who will be happy to
                assist you!
              </div>
            )}
            {screenType === ScreenType.PAID && (
              <div className="paid">
                <h3>
                  Thank you for your generous donation of {amountToString(donationAmt * 100)}!
                </h3>
              </div>
            )}

          </div>
        </div>

      </div>
    </>
  );
};

export default Donations;
