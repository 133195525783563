import { Config } from '../../Config';
import { IStaff, IStaffResponse, IStaffWithTokResponse, IValidResponse } from '../../models/staff/iStaff';
import { StaffFields } from '../../models/staff/StaffFields';
import { getFromStorage, removeKeyFromStorage, setToStorage } from '../../services/IonicStorage';
import { AxiosController } from '../AxiosController';
import { PathEnum } from '../PathEnum';

export class StaffController extends AxiosController {
    private PATH: string = `${Config.REMOTE_SERVER_ADDR}${PathEnum.STAFF}`;

    /**
     * Generates an OTP code set to the
     * provided phone number
     * @param phone : string
     * @returns IValidResponse
     */
    public async getCode(
        phone: string,
    ): Promise<IValidResponse> {
        const validResponse = await this.axiosPOST<IValidResponse>(
            `${this.PATH}/getCode`,
            {
                [StaffFields.phone]: phone,
            }
        );

        return validResponse;
    }

    /**
     * Verifies OTP code against provided
     * phone number and sets Staff in
     * localStorage
     * @param phone : string
     * @param code : string
     * @returns IStaffWithTokResponse
     */
    public async login(
        setStaff: (Staff: IStaff) => void,
    ): Promise<IStaffWithTokResponse> {
        const StaffResponse = await this.axiosPOST<IStaffWithTokResponse>(
            `${this.PATH}/login`,
            {
            }
        );

        await setToStorage(StaffFields.staffTok, StaffResponse.data.staffTok);
        await setToStorage(StaffFields.staff, StaffResponse.data.staff);
        setStaff(StaffResponse.data.staff);
        return StaffResponse;
    }

    /**
     * Verifies StaffId against Staff
     * @param StaffId : string
     * @returns IStaffResponse
     */
    public async verify(
        StaffId: string,
    ): Promise<IStaffResponse> {
        const StaffResponse = await this.axiosPOST<IStaffResponse>(
            `${this.PATH}/verify`,
            {
                [StaffFields.staffId]: StaffId,
            },
            true
        );

        return StaffResponse;
    }


    /**
     * Gets Staff from Ionic Storage
     * @returns IStaff | null
     */
    public async getStaff(): Promise<IStaff | null> {
        const user: IStaff | null = await getFromStorage(StaffFields.staff);
        return user;
    }

    public async logout(
        setStaff: (Staff: null) => void
    ): Promise<void> {
        setStaff(null);
        await removeKeyFromStorage(StaffFields.staffTok);
        await removeKeyFromStorage(StaffFields.staff);
    }


}
