import axios, { AxiosResponse } from 'axios';
import { Config } from '../Config';
import { Dict } from '../models/Dict';
import { StaffFields } from '../models/staff/StaffFields';
import { getFromStorage } from '../services/IonicStorage';

export interface ResponseSchema {

    data: {
        [key: string]: any,
    },
    twilSuccess?: boolean;

}

interface SDCAxiosResp<T extends ResponseSchema> extends AxiosResponse<T> { }

export abstract class AxiosController {

    protected async axiosGET<T extends ResponseSchema>(
        path: string, params: Dict<any>, tok: boolean = false,
    ): Promise<T> {
        const galaId = Config.GALA_ID;
        const staffTok: string | null = await getFromStorage(StaffFields.staffTok);
        const axiosResp: SDCAxiosResp<T> = await axios.get(path, {
            params: {
                ...params,
                staffTok: tok ? staffTok : undefined,
                galaId: galaId,
            },
        }).catch((error) => {
            throw error;
        });

        const respData: T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPOST<T extends ResponseSchema>(
        path: string, body: Dict<any>, tok: boolean = false,
    ): Promise<T> {
        const galaId = Config.GALA_ID;
        const staffTok: string | null = await getFromStorage(StaffFields.staffTok);
        const axiosResp: SDCAxiosResp<T> = await axios.post(path, {
            ...body,
            staffTok: tok ? staffTok : undefined,
            galaId: galaId,
        }).catch((error) => {
            throw error;
        });

        const respData: T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosPUT<T extends ResponseSchema>(
        path: string, body: Dict<any>, tok: boolean = false,
    ): Promise<T> {
        const galaId = Config.GALA_ID;
        const staffTok: string | null = await getFromStorage(StaffFields.staffTok);
        const axiosResp: SDCAxiosResp<T> = await axios.put(path, {
            ...body,
            staffTok: tok ? staffTok : undefined,
            galaId: galaId,
        }).catch((error) => {
            throw error;
        });

        const respData: T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    protected async axiosDELETE<T extends ResponseSchema>(
        path: string, params: Dict<any>, tok: boolean = false,
    ): Promise<T> {
        const galaId = Config.GALA_ID;
        const staffTok: string | null = await getFromStorage(StaffFields.staffTok);
        const axiosResp: SDCAxiosResp<T> = await axios.delete(path, {
            params: {
                ...params,
                staffTok: tok ? staffTok : undefined,
                galaId: galaId,
            },
        }).catch((error) => {
            throw error;
        });

        const respData: T = this.loadAxiosResp(axiosResp);
        return respData;
    }

    private loadAxiosResp<T extends ResponseSchema>(axiosResp: SDCAxiosResp<T>): T {
        // Resp Data
        const respData: T = axiosResp.data;

        // Check if response has twil
        const twilSuccess: boolean | undefined = axiosResp.data.twilSuccess;
        if (twilSuccess !== undefined) respData.twilSuccess = twilSuccess;

        return respData;
    }
}
