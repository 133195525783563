import { IonContent, IonModal, IonPage } from '@ionic/react';
import { useContext } from 'react';
import { Factory } from '../../controllers/ControllerFactory';
import { StaffContext, StaffContextType } from '../../services/StaffContext';
import mascotImg from '../../assets/mascot.png';
import welcomeImg from '../../assets/welcome.svg';
import './Home.scss';

const Home = (props: {killed: boolean}) => {
  const staffController = Factory.StaffController;
  const { staff, setStaff } = useContext(StaffContext) as StaffContextType;
  const handleLogout = () => {
    staffController.logout(setStaff);
  }
  return (
    <IonPage>
      <IonContent fullscreen className="homeContent">
        <IonModal isOpen={props.killed}>
          <p style={
            { color: 'black', width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
              alignItems: 'center', fontSize: '1.5rem', textAlign: 'center'
            }
          }>
            The app is under maintanence.<br/>Please check back later.
          </p>
        </IonModal>
        <div className="container homeContainer">
          <img src={mascotImg} alt="mascot" className="mascot" />
          <div className="content">
            <img src={welcomeImg} alt="Presenting" className="welcome" />
            <h1>Welcome Volunteer,</h1>
            <h2><a href="https://docs.google.com/document/d/1qAVY6uo7TV8avfQM5eAHz14hvGtWRXo0K4tnu6_8HzU/edit?usp=sharing">Link To Schedule</a></h2>

            <h2><a href="https://docs.google.com/presentation/d/1MWHoUyYE6IfPJWy-JKbXXBD9KvU_lSy-ZjJduhBmtqk/edit?usp=sharing">Instruction Manual</a></h2>
            <button onClick={handleLogout}>Logout</button>
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
