import { QrReader } from 'react-qr-reader';

interface QRScanProps {
    setValue: (val: string) => void;
  }
  
const QRScan: React.FC<QRScanProps> = ({
    setValue,
}) => {

    const handleError = (err: any) => {
        console.log(err)
    }

    const handleScan = (result: any) => {
        if (result) {
            setValue(`${result}`)
        }
    }

    return (
        <QrReader
            scanDelay={500}
            // style={previewStyle}
            // onError={handleError}
            onResult={handleScan}
            constraints={{
                facingMode: "environment"
            }}
        />

    );
}

export default QRScan;