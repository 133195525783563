import React, { useState } from 'react';
import { IStaff } from '../models/staff/iStaff';

export type StaffContextType = {
    staff: IStaff | null;
    setStaff: (staff: IStaff | null) => void;
};

export const StaffContext = React.createContext<StaffContextType | null>(null);

export const StaffProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [staff, saveStaff] = useState<IStaff | null>(null);
    const setStaff = (tStaff: IStaff | null) => saveStaff(tStaff);

    return (
        <StaffContext.Provider value={{ staff, setStaff }}>
            {children}
        </StaffContext.Provider>
    );
};