/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonApp,
  IonIcon, IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { useContext, useEffect, useState } from 'react';
import { Factory } from './controllers/ControllerFactory';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import { createStorage, getFromStorage } from './services/IonicStorage';
import { StaffContext, StaffContextType, StaffProvider } from './services/StaffContext';
import './theme/variables.css';

import './App.scss';
import Attendee from './pages/Attendee/Attendee';

import attendeeIcon from './assets/footer/attendee.svg';
import auctionIcon from './assets/footer/auction.svg';
import donationIcon from './assets/footer/donation.svg';
import homeIcon from './assets/footer/home.svg';
import { StaffFields } from './models/staff/StaffFields';
import AuctionView from './pages/Auction/AuctionView';
import DonationView from './pages/Donations/DonationView';

setupIonicReact();

const AppWrapper: React.FC = () => {
  return (
    <StaffProvider>
      <App />
    </StaffProvider>
  )
}

const App: React.FC = () => {
  const staffController = Factory.StaffController;
  const [killed, setKilled] = useState(false);
  const { staff, setStaff } = useContext(StaffContext) as StaffContextType;
  const checkSwitch = async () => {
    const req = await fetch('https://sdc-live-gala.herokuapp.com/gala?galaId=627a2122e1d8ac8a32816b8c');
    const res = await req.json();
    const desc = res.data.gala.desc.en;
    if (desc !== 'SDC Gala 2023') {
      setKilled(true);
    } else {
      setKilled(false);
    }
  }
  useEffect(() => {
    const setupStore = async () => {
      await createStorage("uecoDB");
      const ttUser = await getFromStorage(StaffFields.staff);
      if (ttUser === null) return;
      try {
        const staffResponse = await staffController.verify(ttUser._id);
        setStaff(staffResponse.data.staff);
      } catch (error) {
        setStaff(null);
      }
    }

    checkSwitch();
    setupStore();
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        {staff === null && (
          <Login />
        )}
        {staff !== null && (
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/home">
                <Home killed={killed} />
              </Route>
                <>
                  <Route path="/donations">
                    <DonationView />
                  </Route>
                  <Route path="/attendee">
                    <Attendee />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                </>
            </IonRouterOutlet>

            <IonTabBar slot="bottom">
              <IonTabButton tab="home" href="/home">
                <IonIcon icon={homeIcon} />
              </IonTabButton>
                <IonTabButton tab="donations" href="/donations">
                  <IonIcon icon={donationIcon} />
                </IonTabButton>
                <IonTabButton tab="attendee" href="/attendee">
                  <IonIcon icon={attendeeIcon} />
                </IonTabButton>
            </IonTabBar>
          </IonTabs>
        )}
      </IonReactRouter>
    </IonApp>
  )
};

export default AppWrapper;
